.user-view-btn {
  display: flex;
  justify-content: center;
  padding: 4px;
}



/* ========== Custom Styles Allowlist Modal =========== */

.main-modal.allowlist-modal {
  max-width: 900px !important;
}

.main-modal.allowlist-modal .modal-content .modal-body {
  padding: 10px 150px;
}

.whitelist-modal .modal-content .modal-header hr {
  border-top: 1px solid #ffffff;
}

.whitelist-modal .text-field .MuiFormLabel-root.Mui-focused,
.whitelist-modal .text-field .MuiFormLabel-root,
.whitelist-modal .text-field .MuiInputBase-root,
.whitelist-modal .text-field .MuiFormHelperText-root {
  color: rgb(255, 255, 255) !important;
}

.whitelist-modal .text-field .MuiInput-underline:after,
.whitelist-modal .text-field .MuiInput-underline:before,
.whitelist-modal .text-field .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #ffffff;
}
.action-buttons .add-btn {
  padding: 10px 15px !important;
  margin: 0px 3px !important;
}
.MuiButton-outlined {
  margin: auto 3px !important;
}
.delete-button {
  color: #e05656 !important;
  border: 1px solid rgba(181, 63, 63, 0.5) !important;
}

.delete-button:hover {
    background-color: rgba(181, 63, 63, 0.04) !important;
  }
