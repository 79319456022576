.mint-new {
  display: flex;
  justify-content: center;
}

.main-container .mint-new-NFT {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.MuiButtonBase-root {
  display: flex !important;
}

.css-tlelie-MuiListItemText-root {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}